import React from 'react';
import { FaHome, FaRupeeSign, FaRegClipboard } from 'react-icons/fa';
import CustomersIcon from '../../assets/svg/menu/CustomersIcon';
import InboundOrdersIcon from '../../assets/svg/menu/InboundOrdersIcon';
import OutboundOrdersIcon from '../../assets/svg/menu/OutboundOrdersIcon';
import CatalogIcon from '../../assets/svg/menu/CatalogIcon';
import PickupManagementIcon from '../../assets/svg/menu/PickupManagementIcon';
import UserManagementIcon from '../../assets/svg/menu/UserManagementIcon';

const nav = [
  {
    title: 'Home',
    icon: <FaHome />,
    url: '/',
  },
  {
    title: 'Customers',
    icon: <CustomersIcon />,
    url: '/customers',
  },
  {
    title: 'Inbound Orders',
    icon: <InboundOrdersIcon />,
    url: '/orders/inbound',
  },
  {
    title: 'Outbound Orders',
    icon: <OutboundOrdersIcon />,
    url: '/orders/outbound',
    authorities: [],
  },
  {
    title: 'Catalog',
    icon: <CatalogIcon />,
    url: '/catalog',
  },
  {
    title: 'Pickup Management',
    icon: <PickupManagementIcon />,
    url: '/pickup-management',
  },
  {
    title: 'Reports',
    icon: <FaRegClipboard />,
    url: '/reports',
  },
  {
    title: 'User Management',
    icon: <UserManagementIcon />,
    url: '/users-management',
  },
  {
    title: 'Cash Management',
    icon: <FaRupeeSign />,
    url: '/cash-management',
  },
];

export default nav;
