/* eslint-disable max-len */
import React from 'react';

const CatalogIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-23 -285)">
        <g transform="translate(26 286)">
          <path
            d="M1506.839,949.334a.667.667,0,0,1-.662-.735.691.691,0,0,1,.7-.6h5.786a.69.69,0,0,0,.7-.6.666.666,0,0,0-.662-.735h-5.859a2,2,0,0,0-1.991,1.864h-.007v9.588a1.332,1.332,0,0,0,1.332,1.332h6.925a1.332,1.332,0,0,0,1.332-1.332v-7.457a1.332,1.332,0,0,0-1.332-1.332Z"
            transform="translate(-1504.841 -946.671)"
          />
        </g>
      </g>
    </svg>
  );
};

export default CatalogIcon;
