/* eslint-disable max-len */
import React from 'react';

const CustomersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-22 -135)">
        <g transform="translate(22.96 136)">
          <path
            d="M457.934,1525.671h-8.824a1.635,1.635,0,0,0-1.634,1.634v.654h-.654a.98.98,0,1,0,0,1.961h.654v1.634h-.654a.98.98,0,0,0,0,1.961h.654v1.634h-.654a.98.98,0,1,0,0,1.961h.654v.654a1.635,1.635,0,0,0,1.634,1.634h8.824a1.635,1.635,0,0,0,1.634-1.634v-10.458A1.635,1.635,0,0,0,457.934,1525.671Zm-4.412,3.268a1.471,1.471,0,1,1-1.471,1.471A1.469,1.469,0,0,1,453.521,1528.939Zm2.252,7.19h-4.5A.972.972,0,0,1,450.3,1535a3.338,3.338,0,0,1,6.445,0A.972.972,0,0,1,455.773,1536.13Z"
            transform="translate(-445.841 -1525.671)"
          />
        </g>
      </g>
    </svg>
  );
};

export default CustomersIcon;
