import { createSlice } from '@reduxjs/toolkit';
import _ from 'lodash';

const initialState = {
  config: {},
  businessTypes: null,
  cities: [],
  states: [],
  wards: [],
  theme: {},
  departments: [],
  designations: [],
};

const ConfigSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setAdminConfig: (state, action) => {
      state.config = action.payload;
    },
    setBusinessTypes: (state, action) => {
      state.businessTypes = action.payload;
    },
    setCities: (state, action) => {
      state.cities = action.payload;
      state.states = _(action.payload)
        .groupBy(({ stateId, state: stateName }) =>
          JSON.stringify({ id: stateId, name: stateName })
        )
        .map((v, k) => ({ ...JSON.parse(k), cities: v }))
        .value();
    },
    setWards: (state, action) => {
      state.wards = action.payload;
    },
    setTheme: (state, action) => {
      state.theme = action.payload;
    },
    setDepartments: (state, action) => {
      state.departments = action.payload;
    },
    setDesignations: (state, action) => {
      state.designations = action.payload;
    },
  },
});
export const configActions = ConfigSlice.actions;
export default ConfigSlice.reducer;
