import classNames from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Button, Image } from 'react-bootstrap';
import Loader from './Loader/Loader';

export const Empty = ({
  emptyComponent,
  emptyIcon,
  emptyMessage,
  isFiltered,
  emptyResultMessage,
  action,
  actionText,
  loading,
  hasData,
  className,
}) => (
  <div className="text-center no-data-text my-4">
    {loading ? (
      <Loader />
    ) : (
      <>
        {emptyIcon && <Image className="mb-2" width={150} src={emptyIcon} />}
        <div className="font-semibold" style={{ margin: '0 auto', maxWidth: '500px' }}>
          {isFiltered ? emptyResultMessage : emptyMessage || 'No Data Found!'}
        </div>
        {action && !isFiltered && (
          <Button className="dashed-button mt-3" onClick={action}>
            {actionText || '+ Add New'}
          </Button>
        )}
      </>
    )}
  </div>
);
/**
 * HOC for show empty message or child
 * @param loading - show loading
 * @param emptyComponent - show custom empty component
 * @param emptyMessage - message to show when no data
 * @param action - action function to call when click on button
 * @param actionText - text for action button
 * @param children - children when data present
 * @param className - classname
 * @return {JSX.Element}
 * @constructor
 */
const Content = (props) => {
  const {
    loading,
    emptyComponent,
    emptyMessage,
    emptyIcon,
    action,
    actionText,
    children,
    className,
    isFiltered = true,
    hasData,
  } = props;

  return hasData ? (
    <>
      {children}
      {loading && <Loader overlay />}
    </>
  ) : (
    <tr
      className={classNames(
        'align-items-center bg-transparent d-flex flex-column h-50 ' +
          'justify-content-center bg-transparent',
        className
      )}>
      <td colSpan="100%" className="border-0">
        <Empty {...props} />
      </td>
    </tr>
  );
};

Content.propTypes = {};

export default Content;
