/* eslint-disable max-len */
import React from 'react';

const OutboundOrdersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-23 -235)">
        <g transform="translate(23.96 238)">
          <path d="M12.573,10.526H1.461A1.463,1.463,0,0,1,0,9.064v-7.6A1.463,1.463,0,0,1,1.461,0H12.573a1.463,1.463,0,0,1,1.461,1.461v7.6A1.463,1.463,0,0,1,12.573,10.526ZM5.266,5.736a.52.52,0,0,0-.37.153L2.686,8.1a.524.524,0,0,0,0,.74l.247.247a.522.522,0,0,0,.74,0L5.142,7.617a.175.175,0,0,1,.124-.051.173.173,0,0,1,.123.051L6.725,8.952a.523.523,0,0,0,.74,0L9.911,6.506,10.3,6.9a.348.348,0,0,0,.6-.247V4.879a.349.349,0,0,0-.349-.349H8.777a.349.349,0,0,0-.247.6l.394.394L7.219,7.225a.176.176,0,0,1-.247,0L5.636,5.89A.52.52,0,0,0,5.266,5.736ZM2.631,2.047a.585.585,0,1,0,0,1.169H11.4a.585.585,0,0,0,0-1.169Z" />
        </g>
      </g>
    </svg>
  );
};

export default OutboundOrdersIcon;
