import { useFormik } from 'formik';
import React from 'react';
import { Button, Col, Form, FormControl, InputGroup, Modal } from 'react-bootstrap';
import { toast } from 'react-toastify';
import { useAsyncEffect, useStakeholders } from '../../hooks';
import { useAccountPassword, useInitApp } from '../../hooks/hooks';
import { fetchAccount, initAppApis } from '../../services/backend-api';
import { hasError } from '../../shared/utils/Helper';
import './UserProfileModal.scss';

const UserProfileModal = (props) => {
  const { show, setShow } = { ...props };
  const [{ loading: passLoading }, { doUpdateAccount, doGetAccount }] = useAccountPassword();
  const { currentAccount } = useStakeholders();
  const [initState, initAppConfig] = useInitApp();

  useAsyncEffect(async () => {
    await doGetAccount();
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: currentAccount?.firstName || '',
      mobile: currentAccount?.mobile || '',
      email: currentAccount?.email || '',
    },
    enableReinitialize: true,
    validateOnBlur: true,
    validateOnChange: true,
    onSubmit: async (values) => {
      const res = await doUpdateAccount(values);
      const error = hasError(res);
      if (error) {
        toast.error(error.message);
      } else {
        await initAppConfig();
        toast.success('Updated Account Details successfully.');
      }
      closeModal();
    },
  });

  const closeModal = () => {
    setShow(false);
  };

  return (
    <Modal
      show={show}
      onHide={closeModal}
      onExit={() => formik.resetForm()}
      backdrop="static"
      size="md"
      centered>
      <Modal.Header closeButton onHide={closeModal}>
        <Modal.Title>Profile</Modal.Title>
      </Modal.Header>
      <Modal.Body className="p-4">
        <Form noValidate>
          <Form.Row>
            <Form.Group as={Col} md="12">
              <Form.Label>First Name</Form.Label>
              <InputGroup>
                <FormControl
                  type="text"
                  placeholder="Enter First Name"
                  {...formik.getFieldProps('firstName')}
                  isInvalid={formik.touched.firstName && !!formik.errors.firstName}
                />
                <Form.Control.Feedback type="invalid">
                  {formik.errors.firstName}
                </Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <Form.Label>Mobile Number</Form.Label>
              <InputGroup>
                <FormControl
                  type="text"
                  placeholder="Enter Mobile Number"
                  {...formik.getFieldProps('mobile')}
                  isInvalid={formik.touched.mobile && !!formik.errors.mobile}
                />
                <Form.Control.Feedback type="invalid">{formik.errors.mobile}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
            <Form.Group as={Col} md="12">
              <Form.Label>Email</Form.Label>
              <FormControl
                type="text"
                placeholder="Enter Email"
                disabled="true"
                {...formik.getFieldProps('email')}
                isInvalid={formik.touched.email && !!formik.errors.email}
              />
              <Form.Control.Feedback type="invalid">
                {formik.errors.firstName}
              </Form.Control.Feedback>
            </Form.Group>
          </Form.Row>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" className="pr-5 pl-5 mr-3 cancel-button" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="submit"
          disabled={Object.keys(formik?.touched).length === 0}
          onClick={formik.handleSubmit}
          style={{ padding: '5px 50px 5px 50px' }}>
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export default UserProfileModal;
