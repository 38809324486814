import { keyBy } from 'lodash';
import { useSelector } from 'react-redux';

export default function useAppConfig() {
  const {
    businessTypes,
    cities,
    states,
    wards,
    theme,
    locations,
    departments,
    designations,
  } = useSelector(({ config, location }) => ({
    ...config,
    ...location,
  }));
  return {
    businessTypes,
    businessTypesMap: keyBy(businessTypes, 'id'),
    states,
    statesMap: keyBy(states, 'id'),
    cities,
    citiesMap: keyBy(cities, 'id'),
    wards,
    wardsMap: keyBy(wards, 'id'),
    locations,
    locationsMap: keyBy(locations, 'id'),
    theme,
    departments,
    departmentsMap: keyBy(departments, 'id'),
    designations,
    designationsMap: keyBy(designations, 'id'),
  };
}
