/* eslint-disable max-len */
import React from 'react';

const PickupManagementIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-23 -434)">
        <g transform="translate(24 436)">
          <path
            d="M1080.228,129.851h7.765a.228.228,0,0,0,.228-.227l.015-4.146h0v-1.124a1.371,1.371,0,0,0-1.385-1.352h-5.466a1.371,1.371,0,0,0-1.385,1.352v5.27A.228.228,0,0,0,1080.228,129.851Z"
            transform="translate(-1080 -123)"
          />
          <path
            d="M1094.153,131.8l-1.417-3.654a.236.236,0,0,0-.221-.148h-3.306a.233.233,0,0,0-.236.228v4.8h-8.737a.232.232,0,0,0-.236.228v1.713a.814.814,0,0,0,.827.8h1.15a1.425,1.425,0,0,0,2.66,0H1090a1.425,1.425,0,0,0,2.66,0h.677a.814.814,0,0,0,.826-.8v-3.083A.227.227,0,0,0,1094.153,131.8Zm-10.847,4.419a.914.914,0,1,1,.945-.913A.931.931,0,0,1,1083.306,136.221Zm8.029,0a.914.914,0,1,1,.944-.913A.931.931,0,0,1,1091.335,136.221Zm1.18-4.567h-2.361a.233.233,0,0,1-.236-.228v-2.284a.236.236,0,0,1,.473,0V131.2h2.125a.229.229,0,1,1,0,.457Z"
            transform="translate(-1080 -125.716)"
          />
        </g>
      </g>
    </svg>
  );
};

export default PickupManagementIcon;
