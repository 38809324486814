const FilterParamMap = {
  USERS: {
    id: 'id.equals',
    firstName: 'name.contains',
    mobile: 'mobile.equals',
    email: 'email.equals',
    activated: 'active.equals',
    page: 'page',
    size: 'size',
    sort: 'sort',
    refresh: 'refresh',
  },

  CUSTOMERS: {
    sortParams: {
      itemIds: 'id',
      itemName: 'item.name',
    },
    size: 'size',
    page: 'page',
    sort: 'sort',
    refresh: 'refresh',
    itemIds: 'itemIds',
    customerId: 'customerId',
    itemName: 'itemName',
    categoryName: 'categoryName',
  },

  CUSTOMER_BULKGENERATOR: {
    qrCode: 'qrCode.equals',
    name: 'name.contains',
    mobile: 'mobile.equals',
    email: 'email.equals',
    zipcode: 'zipcode.equals',
    businessTypeId: 'businessTypeId.equals',
    regStartDate: 'regStartDate',
    regEndDate: 'regEndDate',
    wardNo: 'wardNo.equals',
    size: 'size',
    page: 'page',
    sort: 'sort',
    dummyCustomer: 'dummyCustomer.equals',
    myNetwork: 'myNetwork.equals',
    canBuy: 'canBuy.equals',
    canSale: 'canSale.equals',
    wasteCollectors: 'wasteCollectors.equals',
    generators: 'generators.equals',
    businessName: 'businessName.equals',
    vehicleNumber: 'vehicleNumber.equals',
    fromDate: 'fromDate',
    toDate: 'toDate',
    cityId: 'cityId.equals',
    q: 'q.contains',
    sortParams: {
      name: 'user.firstName',
    },
  },

  CASH_ADVANCE: {
    locationId: 'locationId',
    q: 'q.contains',
    page: 'page',
    size: 'size',
    customerId: 'customerId',
  },

  PICKUP: {
    PRICES: {
      'item.id': 'itemId',
      'item.categoryName': 'categoryName',
      'item.name': 'itemName',
      page: 'page',
      size: 'size',
      sort: 'sort',
      refresh: 'refresh',
    },
  },

  ATTENDANCE: {
    page: 'page',
    size: 'size',
    sort: 'sort',
  },

  ORDER: {
    // to replace param for sorting
    sortParams: {
      delay: 'orderDate',
      orderDate: 'orderDate',
      pickupTime: 'pickupTime',
      statusTime: 'statusTime',
      paymentDate: 'statusTime',
    },
    // to replace param for filtering
    formattedId: 'formattedId',
    id: 'id.equals',
    status: 'status',
    page: 'page',
    size: 'size',
    sort: 'sort',
    orderType: 'orderType',
    sellerName: 'customerId',
    zipcode: 'fromLocationZipcode',
    email: 'email',
    mobile: 'mobile',
    pickupPersons: 'pickupPersonId',
    channelType: 'channelName',
    'seller.businessType.title': 'businessTypeId',
    paymentMode: 'paymentMode',
    buyerName: 'customerId',
    buyerCity: 'toLocationZipcode',
    customerId: 'customerId',
    delay: 'delayedDaysOnCreatedDt',
    createdDate: ['createdStartDate', 'createdEndDate'],
    orderDate: ['stSheduledOrDropDt', 'endSheduledOrDropDt'],
    pickupTime: ['completedStartDate', 'completedEndDate'],
    statusTime: ['statusTimeStart', 'statusTimeEnd'],
    paymentDate: ['statusTimeStart', 'statusTimeEnd'],
  },

  CATALOG: {
    sortParams: {
      itemName: 'item.name',
      categoryName: 'item.category.name',
      itemIds: 'id',
      modifiedAt: 'document.modifiedAt',
      fileName: 'document.fileName',
      totalRows: 'totalRows',
      failedRowsCount: 'failedRowsCount',
    },
    itemName: 'itemName',
    categoryName: 'categoryName',
    itemIds: 'itemIds',
    size: 'size',
    page: 'page',
    sort: 'sort',
    refresh: 'refresh',
    onlySelected: 'onlySelected',
    totalCount: 'totalCount',
    'item.name': 'itemName',
    hsn: 'hsn',
  },

  BAG_TXNS: {
    id: 'id.equals',
    page: 'page',
    size: 'size',
    sort: 'sort',
    'bag.id': 'bag.id.equals',
    generatorId: 'generatorId.equals',
    receivedAtCenter: 'receivedAtCenter',
    contaminated: 'contaminated',
  },
};

export default FilterParamMap;
