import moment from 'moment';
import PropTypes from 'prop-types';
import React from 'react';
import { InputGroup } from 'react-bootstrap';
import { DateRangePicker } from 'react-bootstrap-daterangepicker';
import Form from 'react-bootstrap/Form';
import { toLocalDate, toServerDate } from '../../utils/Helper';

const MpDatePicker = (props) => {
  const {
    startDate,
    endDate,
    isInvalid,
    disabled,
    onApply,
    className = '',
    controlId,
    placeholder,
    inputClass,
    minDate,
    maxDate,
    errorMessage,
    inputProps,
    predefinedRange = false,
    initialSettings = {},
    ...restProps
  } = props;

  let displayValue = startDate
    ? `${toLocalDate(startDate, 'DD-MMM-YY')} to ${toLocalDate(endDate, 'DD-MMM-YY')}`
    : '';
  displayValue = initialSettings?.singleDatePicker
    ? toLocalDate(startDate, 'DD-MMM-YY')
    : displayValue;

  const onDateSelect = ($event, picker) => {
    const { startDate, endDate } = { ...picker };
    const date = {
      startDate: toServerDate(moment(startDate).startOf('day').toISOString()),
      endDate: toServerDate(moment(endDate).endOf('day').toISOString()),
    };
    onApply(date, $event);
  };

  return (
    <DateRangePicker
      autoApply
      onApply={onDateSelect}
      initialSettings={{
        alwaysShowCalendars: true,
        showDropdowns: true,
        opens: 'left',
        maxDate: moment().toDate(),
        linkedCalendars: false,
        ranges: predefinedRange && {
          Today: [moment().toDate(), moment().toDate()],
          Yesterday: [moment().subtract(1, 'days').toDate(), moment().subtract(1, 'days').toDate()],
          'This Week': [moment().startOf('isoweek').toDate(), moment().endOf('isoweek').toDate()],
          'This Month': [moment().startOf('month').toDate(), moment().endOf('month').toDate()],
          'Last Month': [
            moment().subtract(1, 'month').startOf('month').toDate(),
            moment().subtract(1, 'month').endOf('month').toDate(),
          ],
          'This Year': [moment().startOf('year').toDate(), moment().endOf('year').toDate()],
        },
        ...initialSettings,
      }}
      {...restProps}>
      <InputGroup>
        <Form.Control
          isInvalid={isInvalid}
          placeholder={placeholder || 'Select Dates'}
          disabled={disabled}
          autoComplete="off"
          className={`datepicker-field form-control-one ${inputClass}`}
          onChange={() => {}}
          value={displayValue}
          {...inputProps}
        />
        <Form.Control.Feedback type="invalid">{errorMessage}</Form.Control.Feedback>
      </InputGroup>
    </DateRangePicker>
  );
};

MpDatePicker.propTypes = {
  errorMessage: PropTypes.string,
  inputClass: PropTypes.string,
  isInvalid: PropTypes.bool,
  controlId: PropTypes.string,
};

export default MpDatePicker;
