import React, { Suspense, useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import Switch from 'react-bootstrap/Switch';
import { Redirect, Route } from 'react-router-dom';
import { useWindowSize } from 'react-use';
import AppHeader from './features/AppLayout/AppHeader';
import AppSidebar from './features/AppLayout/AppSidebar';
import MainContent from './features/AppLayout/MainContent';
import './features/AppLayout/_AppLayout.scss';
import { useOrientation, useStakeholders } from './hooks';
import { useAccount } from './hooks/hooks';
import useAuthCheck from './hooks/useAuthCheck';
import useResponsive from './hooks/useResponsive';
import AppAnalytics from './services/AppAnalytics';
import { Loader } from './shared/components';

const LoginPage = React.lazy(() => import('./views/LoginPage/LoginPage'));
const ResetPasswordPage = React.lazy(() => import('./views/ResetPasswordPage/ResetPasswordPage'));

function App() {
  const [collapsed, setCollapsed] = useState(false);
  const [toggled, setToggled] = useState(false);
  const { currentAccount } = useStakeholders();
  const { token, loading } = useAuthCheck();
  const { isMobile } = useResponsive();
  useEffect(() => {
    AppAnalytics.initialize();
  }, []);

  const handleCollapsedChange = (checked) => {
    setCollapsed(checked);
  };

  const handleToggleSidebar = (value) => {
    if (!collapsed && isMobile) {
      setToggled(false);
      setCollapsed(!collapsed);
    } else {
      setToggled(value);
      setCollapsed(!collapsed);
    }
  };

  if (!token) {
    return (
      <Suspense fallback={<Loader />}>
        <Container className="h-100">
          <Route name="Login" path="/login" component={LoginPage} />
          <Route
            name="Login"
            path="/reset-password"
            exact
            render={(props) => {
              return token ? <Redirect to="/" /> : <ResetPasswordPage {...props} />;
            }}
          />
        </Container>
      </Suspense>
    );
  }

  return (
    <div className={`app animated fadeIn  ${collapsed ? 'toggled sidebar-collapsed' : ''}`}>
      <AppHeader
        handleToggleSidebar={handleToggleSidebar}
        handleCollapsedChange={handleCollapsedChange}
        toggled={toggled}
        collapsed={collapsed}
      />
      <AppSidebar
        collapsed={collapsed}
        toggled={toggled}
        handleToggleSidebar={handleToggleSidebar}
      />
      {currentAccount && <MainContent />}
    </div>
  );
}

export default App;
