/* eslint-disable max-len */
import React from 'react';

const InboundOrdersIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-22 -185)">
        <g transform="translate(22.96 188)">
          <path
            d="M12.573,0H1.461A1.463,1.463,0,0,0,0,1.462v7.6a1.463,1.463,0,0,0,1.461,1.461H12.573a1.463,1.463,0,0,0,1.461-1.461v-7.6A1.463,1.463,0,0,0,12.573,0ZM5.266,4.79a.52.52,0,0,1-.37-.153L2.686,2.427a.524.524,0,0,1,0-.74l.247-.247a.522.522,0,0,1,.74,0L5.142,2.91a.175.175,0,0,0,.124.051.173.173,0,0,0,.123-.051L6.725,1.574a.523.523,0,0,1,.74,0L9.911,4.02l.394-.394a.348.348,0,0,1,.6.247V5.647A.349.349,0,0,1,10.552,6H8.777a.349.349,0,0,1-.247-.6l.394-.394L7.219,3.3a.176.176,0,0,0-.247,0L5.636,4.636A.52.52,0,0,1,5.266,4.79ZM2.631,8.479a.585.585,0,1,1,0-1.169H11.4a.585.585,0,0,1,0,1.169Z"
            transform="translate(14.034 10.526) rotate(180)"
          />
        </g>
      </g>
    </svg>
  );
};

export default InboundOrdersIcon;
