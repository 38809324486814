/* eslint-disable max-len */
import React from 'react';

const UserManagementIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16">
      <g transform="translate(-953.98 -3773)">
        <g transform="translate(952.274 3774.528)">
          <path
            d="M975.6,3794.645l-2.014-.5-.158-.633a3.739,3.739,0,0,0,1.021-2.025.858.858,0,0,0,.585-.709l.134-1.069a.855.855,0,0,0-.509-.891l.046-.945.186-.186a1.285,1.285,0,0,0,.036-1.735,2.3,2.3,0,0,0-2.072-.948,3.578,3.578,0,0,0-2.043.536c-2.31.053-2.625,1.135-2.625,2.191,0,.232.054.738.092,1.068a.858.858,0,0,0-.549.909l.134,1.069a.859.859,0,0,0,.648.727,3.776,3.776,0,0,0,.97,1.959l-.17.681-2.014.5a3.055,3.055,0,0,0-2.317,2.969.323.323,0,0,0,.323.323h12.291a.325.325,0,0,0,.323-.325A3.055,3.055,0,0,0,975.6,3794.645Z"
            transform="translate(-961.745 -3785)"
          />
        </g>
      </g>
    </svg>
  );
};

export default UserManagementIcon;
