import React from 'react';
import OrdersRoutes from './views/Orders';

const UsersManagementPage = React.lazy(() => import('./views/UsersPage/UsersManagementPage'));
const HomePage = React.lazy(() => import('./views/Home/HomePage'));
const ReportsPage = React.lazy(() => import('./views/ReportsPage/ReportsPage'));
const CashManagement = React.lazy(() => import('./views/CashManagement/CashManagement'));
const CashPaymentView = React.lazy(() => import('./features/Cash/CashPaymentView'));
const PickupManagementRouting = React.lazy(() =>
  import('./views/PickupManagement/PickupManagementRouting')
);
const CustomerDetails = React.lazy(() => import('./views/Customer/CustomerDetails'));
const IndividualGeneratorTabs = React.lazy(() =>
  import('./views/Customer/IndividualGeneratorTabs')
);
const WasteCollectorTabs = React.lazy(() => import('./views/Customer/WasteCollectorTabs'));
const RecyclerTabs = React.lazy(() => import('./views/Customer/RecyclerTabs'));
const AggregatorTabs = React.lazy(() => import('./views/Customer/AggregatorTabs'));
const CustomerManagement = React.lazy(() => import('./views/Customer/CustomerManagement'));
const AggregatorBusinessModal = React.lazy(() =>
  import('./views/Customer/AggregatorBusinessModal')
);
const RecyclerBusinessModal = React.lazy(() => import('./views/Customer/RecyclerBusinessModal'));
const CatalogPage = React.lazy(() => import('./views/Catalog/CatalogPage'));
const ViewItemDetailsPage = React.lazy(() => import('./views/Catalog/ViewItemDetailsPage'));
const EditItemDetailsPage = React.lazy(() => import('./views/Catalog/EditItemDetailsPage'));

const routes = [
  {
    path: '/',
    name: 'Dashboard',
    component: HomePage,
    exact: true,
  },
  {
    path: '/customers/aggregators/add',
    name: 'Customers',
    component: AggregatorBusinessModal,
  },
  {
    path: '/customers/recyclers/add',
    name: 'Customers',
    component: RecyclerBusinessModal,
  },
  {
    path: '/customers/bulk-generators/:id',
    name: 'Customers',
    component: CustomerDetails,
  },
  {
    path: '/customers/individual-generators/:id',
    name: 'Customers',
    component: IndividualGeneratorTabs,
  },
  {
    path: '/customers/waste-collectors/:id',
    name: 'Customers',
    component: WasteCollectorTabs,
  },
  {
    path: '/customers/aggregators/:id',
    name: 'Customers',
    component: AggregatorTabs,
  },
  {
    path: '/customers/recyclers/:id',
    name: 'Customers',
    component: RecyclerTabs,
  },
  {
    path: '/customers',
    name: 'Customers',
    component: CustomerManagement,
  },
  {
    path: '/users-management',
    name: 'User Management',
    component: UsersManagementPage,
  },
  {
    path: '/reports',
    name: 'Reports',
    component: ReportsPage,
  },

  {
    path: '/pickup-management',
    name: 'Pickup Management',
    component: PickupManagementRouting,
  },
  {
    path: '/cash-management',
    name: 'Customers',
    component: CashManagement,
  },
  {
    path: '/advancepayment/view',
    name: 'Customers',
    component: CashPaymentView,
  },
  {
    path: '/orders',
    name: 'Orders',
    component: OrdersRoutes,
  },
  {
    path: '/catalog/my-items/add-update-price/:itemId',
    name: 'View Item',
    component: EditItemDetailsPage,
  },
  {
    path: '/catalog/my-items/item-detail/:itemId',
    name: 'Edit Item',
    component: ViewItemDetailsPage,
  },
  {
    path: '/catalog',
    name: 'Catalog',
    component: CatalogPage,
  },
];

export default routes;
