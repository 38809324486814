import React from 'react';
import classNames from 'classnames';
import './Buttons.scss';

const IconButton = ({
  onClick = () => undefined,
  iconImg,
  iconTitle,
  iconLabel,
  isDisable = false,
}) => {
  return (
    <span
      title={iconTitle}
      onClick={onClick}
      aria-hidden="true"
      className={classNames('ml-2', 'icon-action-button', {
        'disable-element': isDisable,
      })}>
      {iconImg}
      {iconLabel && <span className="p-1">{iconLabel}</span>}
    </span>
  );
};

export default IconButton;
