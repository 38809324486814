import { combineReducers } from '@reduxjs/toolkit';
import AccountUserReducer from './AccountsUser/accountUser.reducer';
import AuthReducer from './Auth/auth.reducer';
import CatalogReducer from './Catalog/catalog.reducer';
import ConfigReducer from './Config/config.reducer';
import locationReducer from './Location/location.reducer';
import UserReducer from './User/user.reducer';

const appReducer = combineReducers({
  user: UserReducer,
  auth: AuthReducer,
  catalog: CatalogReducer,
  config: ConfigReducer,
  accountUser: AccountUserReducer,
  location: locationReducer,
});

const rootReducer = (state, action) => {
  if (action?.type === 'auth/logout') {
    state = undefined;
  }
  return appReducer(state, action);
};

export default rootReducer;
